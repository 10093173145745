import ReactGA from 'react-ga'
import { envKeys, envStages, envUrlKeys } from './types'
const UTILS: any = {}

UTILS.isHttpUrl = (string: string): boolean => {
  const matchPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  const isMatch = matchPattern.test(string)
  return isMatch
}

UTILS.gaLogExternalLink = (from: string, to: string): void => {
  ReactGA.outboundLink(
    {
      label: `${from} - Navigated to: [${to}]`
    },
    () => {
      console.log(`gaLogExternalLink :: ${from} - Navigated to: [${to}]`)
    }
  )
}

UTILS.getEnv = (stage: envStages, isUrl?: boolean): envKeys | envUrlKeys => {
  switch (stage) {
    case 'develop':
      return isUrl === true ? '-dev' : 'dev'
    case 'release':
      return isUrl === true ? '-test' : 'test'
    case 'master':
      return isUrl === true ? '' : 'prod'
  }
}

UTILS.GA = ReactGA

export { UTILS }
