import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
// import { TabsUnstyled, TabsListUnstyled, TabPanelUnstyled, TabUnstyled, TabUnstyledProps } from '@mui/base'
import { /* MuiTabs, */ TabsProps } from '@mui/material/Tabs'
import MuiTabsUnstyled from '@mui/base/TabsUnstyled'
import MuiTabsListUnstyled from '@mui/base/TabsListUnstyled'
import MuiTabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import MuiTabUnstyled from '@mui/base/TabUnstyled'

// import {Tab as MuiTab} from "@mui/material";

import { HuiTabsProps } from './types'
// import UTILS from '../../Utils'

interface TabStyledType extends TabsProps {
  component?: any
  to?: string
  selected?: boolean
}

const Tabs = styled(MuiTabsUnstyled, { shouldForwardProp: () => true })<TabStyledType>(() => ({
  // maxWidth: "50%"
}))

/* const Tabs: FC<TabStyledType> = props => {
  return <TabsStyled variant="scrollable" scrollButtons="auto" {...props} >{props.children}</TabsStyled>
} */

const TabList = styled(MuiTabsListUnstyled, { shouldForwardProp: () => true })(() => ({
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'space-between',
  maxWidth: 50
}))

const TabStyled = styled(MuiTabUnstyled, { shouldForwardProp: () => true })(() => ({
  whiteSpace: 'nowrap',
  color: '#ffffff',
  cursor: 'pointer',
  fontSize: '14.4px',
  fontWeight: '600',
  lineHeight: '19.61px',
  letterSpacing: '-0.48px',
  backgroundColor: 'transparent',
  width: '100%',
  padding: '15px 11px',
  margin: 0,
  border: 'none',
  borderRadius: '6px 6px 0px 0px',
  display: 'flex',
  justifyContent: 'center',
  '&.Mui-selected': {
    backgroundColor: '#ffffff',
    color: 'initial'
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: 'not-allowed'
  }
}))

const TabStyledLink = styled(Link, { shouldForwardProp: () => true })<TabStyledType>(() => ({
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  color: '#ffffff',
  cursor: 'pointer',
  fontSize: '14.4px',
  fontWeight: '600',
  lineHeight: '19.61px',
  letterSpacing: '-0.48px',
  backgroundColor: 'transparent',
  width: '100%',
  padding: '15px 11px',
  margin: 0,
  border: 'none',
  borderRadius: '6px 6px 0px 0px',
  display: 'flex',
  justifyContent: 'center',
  '&.Mui-selected': {
    backgroundColor: '#ffffff',
    color: 'initial'
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: 'not-allowed'
  }
}))

const TabPanel = styled(MuiTabPanelUnstyled, { shouldForwardProp: () => true })(() => ({
  width: '100%',
  fontSize: '0.875rem'
}))

const Tab: FC<HuiTabsProps> = props => {
  const tabProps = (): any => {
    if (props.to !== undefined && props.to !== '') {
      return {
        ...props,
        ...{
          className: 'hui-tab-nav-link',
          to: props.to,
          component: TabStyledLink
        }
      }
    } else {
      return props
    }
  }

  const args = tabProps()
  return <TabStyled {...args}> {args.children} </TabStyled>
}

const HuiTabs: any = {
  Tabs,
  TabList,
  Tab,
  TabPanel
}

export default HuiTabs
