import React from 'react'

import { Skeleton, SkeletonProps } from '@mui/material'

export type HuiSkeletonProps = {} & SkeletonProps

export const HuiSkeleton: React.FC<HuiSkeletonProps> = ({ ...otherProps }) => {
  const skeletonConfig = {
    ...otherProps
  }

  return <Skeleton {...skeletonConfig} />
}
