import React, { FC, BaseSyntheticEvent } from 'react'

import { Box, Button, IconButton, Popover, PopoverProps } from '@mui/material'

import { HuiIcon, HuiIconProps } from './Icon'
import { Optional } from '../types'

export interface HuiPopoverProps extends Optional<PopoverProps, 'open'> {
  ariaLabel?: string
  button?: boolean
  btnText?: string
  icon?: HuiIconProps
  variant?: 'text' | 'outlined' | 'contained'
}

export const HuiPopover: FC<HuiPopoverProps> = ({ ariaLabel = 'info', button = false, btnText = 'Click me', icon, variant, ...otherProps }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { id, children } = otherProps

  const handleClick = (event: BaseSyntheticEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popId = open ? id : undefined

  const configInfoPopover: PopoverProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    ...otherProps,
    id: popId,
    open: open,
    anchorEl: anchorEl,
    onClose: handleClose
  }

  return (
    <Box>
      {button
        ? (
          <Button aria-label={ariaLabel ?? btnText} aria-describedby={popId} variant={variant ?? 'contained'} onClick={handleClick}>
            {btnText}
          </Button>
          )
        : (
          <IconButton aria-label={ariaLabel ?? icon?.name ?? ''} aria-describedby={popId} onClick={handleClick} sx={{ padding: '4px' }}>
            {icon !== undefined && <HuiIcon {...icon} />}
          </IconButton>
          )}
      <Popover {...configInfoPopover}>{children}</Popover>
    </Box>
  )
}
