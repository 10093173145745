import React from 'react'

import { ListItem, ListItemProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { HuiSkeleton } from './Skeleton'

export type HuiListItemProps = {
  hasBorder?: boolean
  isLoading?: boolean
} & ListItemProps

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'isActionList'
})<HuiListItemProps>(({ hasBorder }) => ({
  ...(hasBorder === true && {
    justifyContent: 'space-between !important',
    borderRadius: '0.6rem',
    border: '0.1rem solid #CACACA',
    minHeight: '60px'
  })
}))

export const HuiListItem: React.FC<HuiListItemProps> = ({ children, isLoading = false, hasBorder = false }) => {
  if (isLoading) return <HuiSkeleton height='48px' sx={{ transform: 'scale(1, 1)', margin: '8px 16px' }} />
  return <StyledListItem hasBorder={hasBorder}>{children}</StyledListItem>
}
