import * as React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const ContainerWrapper = styled(Container)({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#414856',
  lineHeight: 1.5,
  fontFamily: 'Open Sans',
  fontSize: '.9rem',
  fontWeight: 100,
  textAlign: 'left',
  color: '#212529',
  a: {
    color: '#E3124B',
    textDecoration: 'none'
  }
})

const BoxWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '.6rem',
  boxShadow: '0px 2rem 4rem rgb(0 0 0 / 25%)',
  backgroundColor: '#E1E6EC',
  maxWidth: '50%',
  boxSizing: 'border-box',
  width: '37.5em',
  div: {
    padding: '1.5rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: '.6rem'
  },
  '.body': {
    marginTop: '0',
    marginBottom: '1rem'
  },
  '.end': {
    marginTop: '0',
    marginBottom: '0',
    padding: '1.5rem'
  }
}))

export const HuiLogout: React.FC = () => {
  return (
    <ContainerWrapper maxWidth={false}>
      <BoxWrapper>
        <div>
          <Typography variant='h1'>Logging out securely</Typography>
          <Typography className='body'>
            <Typography variant='body1'>You are authenticated through <a href='http://ist.mit.edu/touchstone-detail' rel='noopener noreferrer' target='_blank'>MIT Touchstone</a>.</Typography><br /><br />
            MIT Touchstone is a <a href='http://en.wikipedia.org/wiki/Single_sign-on' rel='noopener noreferrer' target='_blank'>single sign-on</a> service that allows members of the MIT community to log in to participating MIT websites. Once you are authenticated via Touchstone, <b>the only way to log out of Touchstone is to close the browser.</b>
          </Typography>
        </div>
        <Typography variant='body1' className='end'>
          Please contact the Help Desk at <a href='mailto:computing-help@mit.edu'>computing-help@mit.edu</a> or 3-1101 for assistance.
        </Typography>

      </BoxWrapper>
    </ContainerWrapper>
  )
}
