import React, { FC, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'

import { library, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
library.add(fad, far, fal, fas, fab, fat)

type HuiIconPrefix = 'regular' | 'light' | 'solid' | 'brands' | 'duotone' | 'fas' | 'far' | 'fal' | 'fat' | 'fad' | 'fab'

export type { IconName, HuiIconPrefix }

export interface HuiIconProps extends FontAwesomeIconProps {
  icon: IconName
  type?: HuiIconPrefix
  fixedWidth?: boolean
  onClick?: () => any | undefined
}

const typeMap = [
  {
    type: 'far',
    long: 'regular'
  },
  {
    type: 'fal',
    long: 'light'
  },
  {
    type: 'fas',
    long: 'solid'
  },
  {
    type: 'fab',
    long: 'brand'
  },
  {
    type: 'fad',
    long: 'duotone'
  },
  {
    type: 'fat',
    long: 'thin'
  }
]

export const HuiIcon: FC<HuiIconProps> = ({
  icon = 'rectangle-portrait',
  type = 'far',
  color,
  pulse = false,
  spin = false,
  className = '',
  border = false,
  inverse = false,
  flip,
  size,
  rotation,
  style,
  tabIndex,
  fixedWidth,
  onClick
}) => {
  const styles = useMemo(() => {
    return { ...style, ...{ cursor: onClick != null && typeof onClick === 'function' ? 'pointer' : '' } }
  }, [onClick, style])

  const clickAction = useMemo(() => onClick?.(), [onClick])

  const getType = (): IconPrefix => [...typeMap].find(o => o.long === type || o.type === type)?.type as IconPrefix

  const getColor = (color?: string): string | undefined => {
    if (color !== undefined && color !== null && color !== '') {
      const theme = useTheme()
      const themeColor = color.split('.').reduce((o: any, i: any) => o[i], theme.palette)
      return themeColor ?? color
    } else {
      return color
    }
  }

  return (
    <FontAwesomeIcon
      icon={[getType(), icon]}
      color={getColor(color)}
      pulse={pulse}
      spin={spin}
      className={className}
      border={border}
      inverse={inverse}
      flip={flip}
      size={size}
      rotation={rotation}
      tabIndex={tabIndex}
      onClick={() => clickAction}
      style={styles}
      fixedWidth={fixedWidth}
    />
    /* (loaded === true && error === false) ?
    (<FontAwesomeIcon
      icon={[type as IconPrefix, icon]}
      color={color}
      pulse={pulse}
      spin={spin}
      className={className}
      border={border}
      inverse={inverse}
      flip={flip}
      size={size}
      rotation={rotation}
      tabIndex={tabIndex}
      onClick={clickAction}
      style={styles}
      fixedWidth={fixedWidth}
    />) : (
      <div>Script failed to load: [{message}]</div>
    ) */
  )
}
