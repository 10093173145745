import React, { useCallback, useMemo } from 'react'

// @mui
import { Box, Button, ButtonGroup, Grid, Paper, Typography, BoxProps, PaperProps } from '@mui/material'
import { styled } from '@mui/material/styles'

// @hui /local imports
import { HuiIcon } from './Icon'
import { HuiPopover } from './Popover'
import { HuiSkeleton } from './Skeleton'

export interface HuiProgressCardsProps {
  maxCol?: number
  isLoading?: boolean
  items?: HuiProgressCardsItem[]
  onConfirm: (value: HuiProgressCardsItem) => void
}

type Status = 'A' | 'P' | 'R' | 'N'

interface ApprovalType {
  id: string
  name: string
  canApprove: boolean
}

export interface HuiProgressCardsItem {
  id: string
  title: string
  response: string
  approvalType: ApprovalType
  currentApprovalStep: boolean
  status: Status /** "A" -> Approved, "P" -> Pending, "R" -> Rejected, "N" -> Not Uploaded */
  comment?: {
    title: string
    message: string
  }
}

interface HuiBoxProps extends BoxProps {
  amount: number
}

interface HuiPaperProps extends PaperProps {
  amount: number
}

const CardContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'amount'
})<HuiBoxProps>(({ theme, amount }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${amount}, 1fr)`,
  [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
    gridTemplateColumns: `repeat(${Math.round(amount / 2).toString()}, 1fr)`
  },
  [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
    gridTemplateColumns: '1fr'
  }
}))

const Card = styled(Paper, {
  shouldForwardProp: prop => prop !== 'amount'
})<HuiPaperProps>(({ theme, amount }) => ({
  borderRadius: 0,
  alignContent: 'start',
  gridRowGap: '10px',
  gridTemplateRows: '70px 80px 90px',
  display: 'grid',
  padding: '20px 10px',
  justifyItems: 'center',
  ':first-child': {
    borderTopLeftRadius: '0.6rem',
    borderBottomLeftRadius: '0.6rem'
  },
  [`:nth-child(${amount.toString()}n)`]: {
    borderTopRightRadius: '0.6rem',
    borderBottomRightRadius: '0.6rem'
  },
  [`:nth-child(${amount.toString()}n+1)`]: {
    borderTopLeftRadius: '0.6rem',
    borderBottomLeftRadius: '0.6rem'
  },
  ':last-child': {
    borderTopRightRadius: '0.6rem !important',
    borderBottomRightRadius: '0.6rem !important'
  },
  [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
    borderTopLeftRadius: '0rem !important',
    borderBottomLeftRadius: '0rem !important',
    borderTopRightRadius: '0rem !important',
    borderBottomRightRadius: '0rem !important',
    [`:nth-child(${Math.round(amount / 2).toString()}n)`]: {
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    },
    [`:nth-child(${Math.round(amount / 2).toString()}n+1)`]: {
      borderTopLeftRadius: '0.6rem !important',
      borderBottomLeftRadius: '0.6rem !important'
    },
    ':last-child': {
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    }
  },
  [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
    ':nth-child(even)': {
      borderTopLeftRadius: '0rem !important',
      borderBottomLeftRadius: '0rem !important',
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    },
    ':nth-child(odd)': {
      borderTopLeftRadius: '0.6rem !important',
      borderBottomLeftRadius: '0.6rem !important',
      borderTopRightRadius: '0rem !important',
      borderBottomRightRadius: '0rem !important'
    },
    ':last-child': {
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    }
  },
  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
    ':nth-child(even)': {
      borderTopLeftRadius: '0.6rem !important',
      borderBottomLeftRadius: '0.6rem !important',
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    },
    ':nth-child(odd)': {
      borderTopLeftRadius: '0.6rem !important',
      borderBottomLeftRadius: '0.6rem !important',
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    },
    ':last-child': {
      borderTopRightRadius: '0.6rem !important',
      borderBottomRightRadius: '0.6rem !important'
    }
  }
}))

export const HuiProgressCards: React.FC<HuiProgressCardsProps> = ({ maxCol = undefined, isLoading = false, items = [], onConfirm }) => {
  const amount = useMemo(() => (maxCol !== undefined ? maxCol : items.length), [items.length, maxCol])

  const approvalsBadge = useCallback((status: string): React.ReactNode => {
    const approvalsBadge: any = {
      a: () => <HuiIcon icon='check-circle' type='fas' color='#029600' size='3x' />,
      p: () => <HuiIcon icon='check-circle' type='fas' color='common.headerBackground.main' size='3x' />,
      r: () => <HuiIcon icon='times-circle' type='fas' color='#E3124B' size='3x' />,
      n: () => <HuiIcon icon='ellipsis-h' type='fas' color='common.headerBackground.main' size='3x' />
    }
    return approvalsBadge[status.toLowerCase()]()
  }, [])

  if (isLoading) {
    return <HuiSkeleton height='67px' sx={{ transform: 'scale(1, 1)', borderRadius: '0.6rem' }} />
  }

  return (
    <Grid item xs={12}>
      <Typography variant='h3' style={{ marginBottom: '16px' }}>
        Account Progress
      </Typography>
      <CardContainer amount={amount}>
        {items.map((item: HuiProgressCardsItem, index: number) => (
          <Card key={`card-${index}`} variant='outlined' amount={amount}>
            <Box className='top'>{approvalsBadge(item.status)}</Box>
            <Box className='info'>
              <Typography variant='h5'>{item.title}</Typography>
              {item.comment !== undefined && (
                <HuiPopover icon={{ icon: 'comment-lines' }}>
                  <Box sx={{ p: 2 }}>
                    <Typography sx={{ fontWeight: 600 }}>{item.comment.title}</Typography>
                    <Typography>{item.comment.message}</Typography>
                  </Box>
                </HuiPopover>
              )}
            </Box>
            <Box className='bottom'>
              {item.approvalType.canApprove && (
                <ButtonGroup variant='outlined' aria-label='Button group'>
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => {
                      onConfirm({ ...item, status: 'R' })
                    }}
                  >
                    <HuiIcon icon='times' type='fas' color='#E3124B' />
                  </Button>
                  <Button
                    variant='outlined'
                    color='success'
                    onClick={() => {
                      onConfirm({ ...item, status: 'A' })
                    }}
                  >
                    <HuiIcon icon='check' type='fas' color='#029600' />
                  </Button>
                </ButtonGroup>
              )}
              <Typography variant='body1'>{item.response}</Typography>
            </Box>
          </Card>
        ))}
      </CardContainer>
    </Grid>
  )
}
