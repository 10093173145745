import React, { FC, useState } from 'react'

import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import usePagination from '@mui/material/usePagination'

type stepDirection = 'forward' | 'backward' | 'same'

export interface onClickReturnPros {
  page: number
  stepDirection: stepDirection
}

export interface HuiPaginationDotProps {
  numberOfDots: number
  onClick: (item: onClickReturnPros) => void
  showNumbers?: boolean
}

interface spanProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  activeStep: boolean
}

const Nav = styled('nav')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

const Span = styled('span', {
  shouldForwardProp: prop => prop !== 'activeStep'
})<spanProps>(({ activeStep }) => ({
  fontSize: '10px',
  fontWeigt: '10',
  color: 'common.white',
  borderRadius: '50%',
  height: '12px',
  width: '12px',
  padding: '2px',
  backgroundColor: 'rgba(0,0,0,0.2)',
  cursor: 'pointer',
  textAlign: 'center',
  lineHeight: '1.2',
  ...(activeStep && {
    fontWeight: 'bolder',
    backgroundColor: 'rgba(0,0,0,0.5)'
  })
}))

export const HuiPaginationDots: FC<HuiPaginationDotProps> = ({ numberOfDots = 1, onClick, showNumbers = true }) => {
  const [activeStep, setActiveStep] = useState(1)

  const handleClick = (event: React.ChangeEvent<unknown>, page: any): void => {
    console.log(event)
    if (activeStep === page) return

    const stepDirection: stepDirection = activeStep < page ? 'forward' : activeStep > page ? 'backward' : 'same'
    setActiveStep(page)
    onClick?.({ page, stepDirection })
  }

  const { items } = usePagination({
    defaultPage: 1,
    boundaryCount: numberOfDots,
    hideNextButton: true,
    hidePrevButton: true,
    count: numberOfDots,
    onChange: handleClick
  })

  return (
    <Nav>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null

        children = (
          <IconButton key={`paging_button_${String(index)}`} type='button' size='small' color='primary' {...item}>
            <Span activeStep={activeStep === page}>{showNumbers && page}</Span>
          </IconButton>
        )

        return <Box key={index}>{children}</Box>
      })}
    </Nav>
  )
}
