import { GlobalNavigationItemProps } from '../src/components/AtlasLayout/types'

export const AtlasApps: GlobalNavigationItemProps[] = [
  {
    appId: 'covid-pass',
    appName: 'Covid Pass',
    icon: 'heart',
    color: '#339CA2',
    href: 'https://covidpass{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'travel',
    appName: 'Travel',
    icon: 'plane',
    color: '#007BC2',
    href: 'https://travel{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'vaccine',
    appName: 'Vaccine',
    icon: 'syringe',
    color: '#339CA2',
    href: 'https://covidvaccine{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'spaces',
    appName: 'Spaces',
    icon: 'building',
    color: '#20447D',
    href: 'https://spaces{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'tim-tickets',
    appName: 'Tim Tickets',
    icon: 'ticket-simple',
    color: '#E3124B',
    href: 'https://visitors{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'onboarding',
    appName: 'Onboarding',
    icon: 'thumbs-up',
    color: '#FFB03A',
    href: 'https://onboarding{env-suffix}.mit.edu',
    target: '_self'
  },
  {
    appId: 'pi-dash',
    appName: 'Pi Dashboard',
    icon: 'file-magnifying-glass',
    color: '#20447D',
    href: 'https://pi-dash{env-suffix}.mit.edu',
    target: '_self'
  }
]
