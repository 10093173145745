import React from 'react'

import { List, ListProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export type HuiListProps = {
  hasBorder?: boolean
} & ListProps

const StyledList = styled(List, {
  shouldForwardProp: prop => prop !== 'isActionList'
})<HuiListProps>(({ hasBorder }) => ({
  ...(hasBorder === true && {
    width: '100%',
    backgroundColor: 'background.paper',
    display: 'grid',
    rowGap: 4
  })
}))

export const HuiList: React.FC<HuiListProps> = ({ children, hasBorder = false }) => {
  return <StyledList hasBorder={hasBorder}>{children}</StyledList>
}
