import React, { FC, HTMLAttributeAnchorTarget, ReactNode, useState } from 'react'
import { Box, IconButton, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, Menu, styled, Tooltip, useMediaQuery } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { IconName } from '@fortawesome/fontawesome-svg-core'

import { GlobalNavigationConfigItemProps, GlobalNavigationConfigProps, GlobalNavigationItemProps } from './types'

import { HuiIcon } from '..'
import { AtlasApps } from '../../../data/AtlasApps'
import { UTILS } from '../../'

interface GlobalNavigationItemsMergedProps extends GlobalNavigationConfigItemProps, GlobalNavigationItemProps {}

interface styledNavItemType extends ListItemButtonProps {
  component?: any
  to?: string
  href?: string
  target?: HTMLAttributeAnchorTarget
  maxIndex: number
  index: number
}

const styleOnlyProps: PropertyKey[] = ['maxIndex', 'index']

const NavItem = styled(ListItemButton, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })<styledNavItemType>(
  ({ theme, maxIndex, index }) => ({
    width: '100%',
    padding: '5px 10px',
    minHeight: '38px',
    tabindex: '0',
    borderBottom: index < maxIndex ? `0.5px solid ${alpha(theme.palette.common.black, 0.12)}` : 'none'
  })
)

const NavItemIcon = styled(ListItemIcon, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })(({ theme }) => ({
  fontSize: '18px !important',
  lineHeight: '18px !important',
  fontWeight: '900 !important',
  minWidth: '22px !important',
  marginRight: '11px',
  color: theme.palette.common.white
}))

const NavItemText = styled(ListItemText, { shouldForwardProp: prop => !styleOnlyProps.includes(prop) })(() => ({
  lineHeight: '19.07px',
  fontSize: '14px !important',
  fontWeight: '600',
  whiteSpace: 'normal',
  '&.primary': {
    lineHeight: '19.07px',
    fontSize: '14px !important',
    fontWeight: '600',
    whiteSpace: 'normal'
  }
}))

export const HuiGlobalAtlasNavigation: FC<GlobalNavigationConfigProps> = ({ env, globalNavigationConfig = [] }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const toggleGlobalNav = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (!open) setAnchorEl(event.currentTarget)
    else setAnchorEl(null)
  }

  const environmentSuffix = UTILS.getEnv(env, true)

  const getGlobalNavItems = (): GlobalNavigationItemsMergedProps[] => {
    return AtlasApps.reduce((acc: GlobalNavigationItemsMergedProps[], curr: GlobalNavigationItemProps) => {
      const searchNode = globalNavigationConfig.find(node => node.appId === curr.appId)
      const newNode: GlobalNavigationItemsMergedProps | null = searchNode != null ? { ...curr, ...searchNode } : null
      if (newNode != null) {
        if (newNode.href !== null && newNode.href !== '' && newNode.href !== undefined) {
          newNode.href = newNode.href.replace('{env-suffix}', environmentSuffix)
        }

        acc.push(newNode)
      }
      return acc
    }, [])
  }

  const getGlobalNavItem = (item: GlobalNavigationItemsMergedProps, i: number): ReactNode => {
    const maxIndex: number = getGlobalNavItems().length - 1
    const { appId, appName, icon, color, href, enabled = true, target = '_self' } = item
    return (
      <NavItem
        className='global-nav-item'
        component='button'
        key={`global-nav-${i}-${appId}`}
        href={href}
        target={target}
        onClick={() => (appName !== null && appName !== undefined && appName !== '' && href !== null && href !== undefined && href !== '') ? UTILS.gaLogExternalLink(`${appName} GLOBAL NAV`, href) : undefined}
        disabled={!enabled}
        dense
        index={i}
        maxIndex={maxIndex}
      >
        <NavItemIcon>
          <HuiIcon className='utility-nav-link' icon={icon as IconName} type='fal' color={color} />
        </NavItemIcon>
        <NavItemText
          sx={{
            textDecoration: 'none !important',
            color: 'common.black'
          }}
        >
          {appName}
        </NavItemText>
      </NavItem>
    )
  }

  return (
    <>
      <Tooltip title='Atlas Global Navigation.'>
        <IconButton className='utility-nav-more-button' size='small' onClick={toggleGlobalNav}>
          <HuiIcon icon='grid' type='fal' style={{ margin: '0 5px' }} color={isSmall ? 'common.white' : 'primary.main'} />
        </IconButton>
      </Tooltip>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', m: 0, p: 0 }}>
        <Menu
          id='global-nav-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={toggleGlobalNav}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          MenuListProps={{
            sx: { px: 2, m: 0 }
          }}
          PaperProps={{
            elevation: 1,
            sx: {
              bgcolor: 'background.paper',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
        >
          {getGlobalNavItems().map((item: any, i: number) => getGlobalNavItem(item, i))}
        </Menu>
      </Box>
    </>
  )
}
