import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { AtlasLogoProps } from './types'

const AtlasEmblem = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(() => ({
  maxWidth: '32px',
  minWidth: '32px'
}))

export const AtlasLogo: FC<AtlasLogoProps> = ({ dark = false, sx }) => {
  return (
    <AtlasEmblem sx={{ ...sx, ...{ sx: { display: 'flex' } } }}>
      <svg viewBox='0 0 49 54' version='1.1'>
        <title>Atlas Logo</title>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Primary-Menu' transform='translate(-22.000000, -21.000000)'>
            <g id='Logo' transform='translate(22.000000, 21.000000)'>
              <path
                d='M28.3506045,0 L49,54 L32.1588946,54 L29.3238342,46.6401869 L15.275475,46.6401869 L12.3557858,54 L8.50518135,54 L0,40.5 L15.275475,0 L28.3506045,0 Z M22.1727116,23.4672897 L18.5759931,33.3084112 L25.8963731,33.3084112 L22.1727116,23.4672897 Z'
                id='Combined-Shape'
                fill={dark ? '#000000' : '#FFFFFF'}
              />
              <polygon id='Path-4' fill='#DE4141' points='0 40.5 11 40 8.5 54' />
            </g>
          </g>
        </g>
      </svg>
    </AtlasEmblem>
  )
}
