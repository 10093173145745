import React, { FC } from 'react'
import { Theme, ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import { merge } from 'lodash'

import DefaultTheme from './themes/DefaultTheme.json'
import AstronautTheme from './themes/AstronautTheme.json'
import NeptuneTheme from './themes/NeptuneTheme.json'
import KeppelTheme from './themes/KeppelTheme.json'
import CrimsonTheme from './themes/CrimsonTheme.json'
import PiDashTheme from './themes/PiDashTheme.json'

interface ThemeProps {
  variant?: 'default' | 'astronaut' | 'neptune' | 'keppel' | 'crimson' | 'pidash'
  themeOverride?: Theme
}

export const HuiThemeProvider: FC<ThemeProps> = ({ children, variant, themeOverride = {} }) => {
  const getTheme = (): Theme => {
    let theme = null

    switch (variant) {
      case 'astronaut':
        theme = AstronautTheme as Theme
        break
      case 'neptune':
        theme = NeptuneTheme as Theme
        break
      case 'keppel':
        theme = KeppelTheme as Theme
        break
      case 'crimson':
        theme = CrimsonTheme as Theme
        break
      case 'pidash':
        theme = PiDashTheme as Theme
        break
      default:
        theme = DefaultTheme as unknown as Theme
        break
    }

    const mergedT = merge(merge(DefaultTheme, theme), themeOverride)

    return createTheme(mergedT)
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme()}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
